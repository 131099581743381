import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ServiceCategoryModel } from '../../modules/admin/modules/service/classes/category/model/service.category.model';
import { CatBtnComponent } from '../cat-btn/cat-btn.component';
import { TranslationModule } from '@ckmk/angular';

@Component({
  selector: 'ser-cat-btn',
  imports: [CatBtnComponent, TranslationModule],
  templateUrl: './ser-cat-btn.component.html',
  styleUrl: './ser-cat-btn.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SerCatBtnComponent implements OnInit, OnChanges {
  @Input({
    required: true,
  })
  public category!: ServiceCategoryModel;

  public constructor(private readonly _self: ElementRef) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges) {}
}
